import { supply_point_profile } from 'app/enums/profile';

import messages from './messages';
import product_type from './product_type';
import { period, meter_reading_period } from './period';
import payout_provision_type from './payout_provision_type';
import { category, document_category_code } from './category';
import { commodity_full, commodity_short } from './commodity';

export const enums = {
  category,
  commodity_full,
  commodity_short,
  document_category_code,
  messages,
  meter_reading_period,
  payout_provision_type,
  period,
  product_type,
  supply_point_profile,
};

export type EnumName = keyof typeof enums;

export default enums;
