import { ReactNode } from 'react';
import { Route, Navigate } from 'react-router-dom';

export type RouteDef =
  | {
      index?: true;
      path: string;
      label?: string;
      element: ReactNode;
      routes?: RouteDef[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      name?: [(params: any) => string, (d: any) => string] | string;
    }
  | { path: string; type: 'default' }
  | { path: string; type: 'prefix'; routes: RouteDef[] }
  | {
      type: 'index';
      label: string;
      element: ReactNode;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      name?: [(params: any) => string, (d: any) => string] | string;
    };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function renderRoutes(config: any[]) {
  return config.map((r) => {
    if ('type' in r && r.type === 'prefix') {
      return (
        <Route path={r.path + '/*'} key={r.path}>
          {renderRoutes(r.routes)}
        </Route>
      );
    }

    if ('type' in r && r.type === 'index') {
      return <Route element={r.element} key="index" path="*" index />;
    }

    if ('type' in r && r.type === 'default') {
      return <Route element={<Navigate to={r.path} replace />} key="default" index />;
    }

    return (
      <Route path={r.path + (r.routes ? '/*' : '')} element={r.element} index={r.index} key={r.path}>
        {r.routes && renderRoutes(r.routes)}
      </Route>
    );
  });
}
