import { lazy, useMemo } from 'react';
import { useSupplyPointTabs } from 'supplyPoint/pages/SupplyPointDetail/tabs/useSupplyPointTabs';

import t from 'core/helpers/t';
import { RouteDef } from 'app/helpers/renderRoutes';
import { SupplyPointModel } from 'app/api/requests';

const SupplyPointList = lazy(() => import('supplyPoint/pages/SupplyPointList'));
const SupplyPointDetail = lazy(() => import('supplyPoint/pages/SupplyPointDetail'));

export function useSupplyPointRoutes() {
  const supplyPointTabs = useSupplyPointTabs();

  return useMemo(
    (): RouteDef[] => [
      {
        path: 'supply-points',
        routes: [
          { element: <SupplyPointList />, label: t('Zoznam odberných miest'), type: 'index' },
          {
            element: <SupplyPointDetail />,
            label: t('Odberné miesto'),
            name: [
              ({ supplyPointId }: { supplyPointId: string }) => `/supply-points/${supplyPointId}`,
              (res: SupplyPointModel) =>
                res.distributions_electricity?.eic?.toUpperCase() ?? res.distributions_gas?.pod?.toUpperCase() ?? '',
            ],
            path: ':supplyPointId',
            routes: supplyPointTabs,
          },
        ],
        type: 'prefix',
      },
    ],
    [supplyPointTabs]
  );
}
