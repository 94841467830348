import { useParams } from 'react-router-dom';

import useDetail from 'core/api/useDetail';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  route: any;
}

export default function BreadcrumbName({ route }: Props) {
  const params = useParams();
  const { data } = useDetail(route.name[0](params));

  // @ts-ignore TODO: ...
  return data ? route.name[1](data) : route.label;
}
