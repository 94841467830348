import t from 'core/helpers/t';

const messages = {
  created: t('Uloženie prebehlo úspešne.'),
  'creating-failed': t('Uloženie neúspešné.'),
  deleted: t('Mazanie prebehlo úspešne.'),
  'deleting-failed': t('Mazanie neúspešné'),
  'deleting-not-allowed': t('Mazanie nie je možné.'),
  'does-not-exist': t('Položka sa nenašla.'),
  edited: t('Aktualizácia prebehla úspešne.'),
  'not-partner-of-energo': t('Dodávateľ nie je partnerom ENERGO.'),
  unknown: t('Neznáma chyba'),
  'updating-failed': t('Aktualizácia neúspešná'),
};

export default messages;
