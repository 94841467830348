import { lazy, useMemo } from 'react';

import t from 'core/helpers/t';
import { RouteDef } from 'app/helpers/renderRoutes';

const UserList = lazy(() => import('user/pages/UserList'));

export function useUserRoutes() {
  return useMemo(
    (): RouteDef[] => [
      {
        path: 'users',
        routes: [{ element: <UserList />, label: t('Správa používateľov'), type: 'index' }],
        type: 'prefix',
      },
    ],
    []
  );
}
