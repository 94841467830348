import { matchPath } from 'react-router-dom'; // Import React Router match utility

import { RouteDef } from './renderRoutes';

export default function getBreadcrumbs(routes: RouteDef[], currentPath: string) {
  const br: RouteDef[] = [];
  let prefix = '/';
  let res = getNode(routes, currentPath, prefix);

  while (res) {
    br.push(
      // @ts-ignore TODO: ...
      'type' in res && res.type === 'prefix' ? { ...res.routes.find((x) => x.type === 'index'), path: res.path } : res
    );

    if ('path' in res) {
      prefix += res.path + '/';
    }

    if ('routes' in res && res.routes) {
      res = getNode(res.routes, currentPath, prefix);
    } else {
      res = undefined;
    }
  }

  return br;
}

export function getNode(routes: RouteDef[], currentPath: string, prefix?: string) {
  return routes.find((route) => {
    if ('path' in route) {
      const mm = prefix + (route.type === 'prefix' || 'routes' in route ? `${route.path}/*` : route.path);

      const m = matchPath(mm, currentPath);

      if (m) {
        return true;
      }
      return false;
    }
  });
}
