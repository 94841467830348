import { useNavigate } from 'react-router-dom';
import { useQuery, DefaultError } from '@tanstack/react-query';

import t from '../helpers/t';

interface DetailResponse<D> {
  data: D;
  meta?: {
    'response-info'?: {
      type: string;
      code: string;
      message: string;
    };
  };
}

const getBaseUrl = (url: string) => {
  const parts = url.replace(/^\//, '').split('/');
  return `/${parts.slice(0, -1).join('/')}`;
};

/**
 *
 * @param url
 * @returns
 */
export default function useDetail<D>(url: string) {
  const navigate = useNavigate();

  const { data, isLoading } = useQuery<unknown, DefaultError, DetailResponse<D>>({
    queryKey: url.replace(/^\//, '').split('/'),
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  const errorInfo = data?.meta?.['response-info'];

  // TODO: by the SingleResponsibilityPRincipe this is bad practice, and I suggest to create separate hook, or make this redirect conditional
  // this hook is intended to be used as data source nothing more/nothing less
  // maybe useRedirectDetail() ??
  if (errorInfo) {
    const redirectPath = getBaseUrl(url);
    navigate(redirectPath, {
      state: { errorCode: errorInfo.code, errorMessage: t(errorInfo.message) },
    });
  }

  return { data: data?.data, isLoading };
}
