import t from 'core/helpers/t';

export const meter_reading_period = {
  annually: t('Ročne'),
  monthly: t('Mesačne'),
};

export const period = {
  annually: t('Ročne'),
  monthly: t('Mesačne'),
  quarterly: t('Kvartálne'),
};
