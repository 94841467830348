import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import { useRef, useState, Fragment, ReactNode, useEffect } from 'react';

interface DropdownOption {
  link: string;
  label: string;
  icon?: ReactNode;
}

interface Props {
  label?: string;
  icon?: ReactNode;
  options: DropdownOption[];
}

export default function DropdownLink({ icon, label, options }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  // Check if any option is active
  const isAnyOptionActive = options.some((option) => location.pathname === option.link);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left">
      <button
        className={clsx(
          'flex items-center justify-center gap-2 px-6 py-4 tracking-[0.03rem] transition duration-200 ease-in-out focus:outline-none focus:ring-0',
          {
            'text-accent bg-sidebarActive': isOpen || isAnyOptionActive, // Apply styles when open or if any option is active
          }
        )}
        onClick={toggleDropdown}
        type="button"
      >
        {icon && <span>{icon}</span>}
        {label}
      </button>

      <div
        className={clsx(
          'card absolute left-12 top-8 z-10 min-w-full max-w-56 overflow-hidden rounded-md border border-neutral-600 bg-primary-800 shadow-md transition-all duration-300 ease-in-out lg:min-w-52',
          isOpen ? 'scale-100 opacity-100' : 'pointer-events-none scale-95 opacity-0'
        )}
        ref={selectRef}
      >
        {options.map((option, index) => (
          <Fragment key={index}>
            <NavLink
              className={({ isActive }) =>
                clsx('w-full p-2 text-white hover:text-primary-100 focus:outline-none focus:ring-0', {
                  'text-accent bg-sidebarActive': isActive,
                })
              }
              onClick={() => setIsOpen(false)}
              to={option.link}
              type="button"
            >
              <div className="flex items-center gap-2">
                {option.icon}
                {option.label}
              </div>
            </NavLink>
          </Fragment>
        ))}
      </div>
    </div>
  );
}
