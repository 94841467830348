import t from 'core/helpers/t.ts';
import { EnergyDeliveryModel } from 'app/api/requests';

export const getDeliveryName = (data?: EnergyDeliveryModel): string => {
  const { commodity_code, id } = data ?? {};

  return t(`D${commodity_code?.toUpperCase()}-${id}`);
};

export const getDeliveryStatus = (
  commodityCode: 'ee' | 'zp' | null,
  productType: 'fix_spot' | 'fix' | 'spot' | null,
  priceVT?: null | number,
  priceVTSpot?: null | number,
  priceDelivery?: null | number,
  priceDeliverySpot?: null | number,
  amountVT?: null | number,
  amount?: null | number
): boolean =>
  (commodityCode === 'ee' &&
    ((productType === 'fix' && priceVT! > 0 && amountVT! > 0) ||
      (productType === 'spot' && priceVTSpot! > 0 && amountVT! > 0) ||
      (productType === 'fix_spot' && priceVT! > 0 && priceVTSpot! > 0 && amountVT! > 0))) ||
  (commodityCode === 'zp' &&
    ((productType === 'fix' && priceDelivery! > 0 && amount! > 0) ||
      (productType === 'spot' && priceDeliverySpot! > 0 && amount! > 0) ||
      (productType === 'fix_spot' && priceDelivery! > 0 && priceDeliverySpot! > 0 && amount! > 0)));
