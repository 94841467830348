/**
 * Display app version fixed to Html root
 */
export default function AppVersion() {
  const version = import.meta.env.VITE_APP_VERSION;

  if (!version) {
    return;
  }

  return <div className="fixed bottom-2 right-2 text-sm text-neutral-300">{version}</div>;
}
