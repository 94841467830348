import { Link } from 'react-router-dom';

import t from 'core/helpers/t';

import AuthLayout from '../components/AuthLayout';

export default function NotFoundPage() {
  return (
    <AuthLayout>
      <h1 className="text-6xl">404</h1>
      <p>
        {t('Stránka sa nenašla, pokračujte ')} <Link to="/">{t('Domov')}</Link>.
      </p>
    </AuthLayout>
  );
}
