import clsx from 'clsx';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import Tooltip from 'core/components/Tooltip';

interface Props {
  link: string;
  label: string;
  icon: ReactNode;
}

export default function LinkItem({ icon, label, link }: Props) {
  return (
    <NavLink
      className={({ isActive }) =>
        clsx('flex w-full justify-center py-4 text-white hover:text-white', {
          'text-accent bg-sidebarActive': isActive,
        })
      }
      to={link}
    >
      <Tooltip content={label}>{icon}</Tooltip>
    </NavLink>
  );
}
