import { ReactNode } from 'react';

import t from 'core/helpers/t';
import BreadcrumbName from 'app/components/BreadcrumbName';

import { RouteDef } from './renderRoutes';

export default function getTitleFromBreadcrumbs(routes?: RouteDef[]): ReactNode {
  const r = routes?.find((r) => 'path' in r && r.path.includes(':')) || routes?.[0];

  if (!r) {
    return t('Ocean');
  }

  // @ts-ignore TODO: ...
  const label = r.label || '';
  // @ts-ignore TODO: ...
  if (r.name) {
    return (
      <>
        {label}: <BreadcrumbName route={r} />
      </>
    );
  }

  return label;
}
