import clsx from 'clsx';
import { useState, PropsWithChildren } from 'react';
import { LiaEnvelope, LiaPhoneSolid } from 'react-icons/lia';

import t from 'core/helpers/t';
import loginLoopLow from 'app/assets/login-loop-low-q.mp4';
import oceanLogoDark from 'app/assets/image/ocean-logo-dark.png';
import energoLogo2024 from 'app/assets/image/energo-logo-2024.png';
import oceanLogoOutline from 'app/assets/image/ocean-logo-outline.png';

interface Props extends PropsWithChildren {
  title?: string;
}

export default function AuthLayout({ children, title }: Props) {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  return (
    <div className="flex min-h-screen md:flex-row">
      <div
        className="relative flex min-h-screen w-full flex-col
          items-center justify-start bg-neutral-100 p-8 sm:justify-between  md:w-1/2 md:bg-white md:p-4"
      >
        <div
          className={clsx(
            'flex w-full max-w-md flex-col gap-6',
            'sm:mt-2 md:flex md:grow md:flex-col md:justify-center'
          )}
        >
          {/* Show logo only on small screens */}
          <img
            className="relative my-2 flex justify-center opacity-100 transition-opacity duration-500 md:hidden"
            alt={t('Supplier Logo')}
            src={oceanLogoDark}
          />

          {title && <h2 className="text-center sm:mb-2 md:mb-10">{title}</h2>}

          {children}
        </div>

        <div className="mt-auto w-full px-4 text-center">
          <p className="mb-2 text-sm text-neutral-800 md:text-base">
            {t('Klientský portál prevádzkovaný spoločnosťou')}
            <a className="focus:outline-none focus:ring-0" href="https://energo.sk" rel="noreferrer" target="_blank">
              <img
                className="m-2 inline-block w-16 transition-opacity duration-500 md:w-20"
                src={energoLogo2024}
                alt="ENERGO Logo"
              />
            </a>
          </p>
          <p className="flex flex-col items-center justify-center gap-4 text-center text-neutral-500 md:flex-row">
            <a
              className="inline-flex items-center text-neutral-500 hover:text-neutral-700 focus:outline-none focus:ring-0"
              href="mailto:dispecing@energo.sk"
            >
              <LiaEnvelope className="mr-2 inline-block" /> {t('dispecing@energo.sk')}
            </a>
            <a
              className="inline-flex items-center text-neutral-500 hover:text-neutral-700 focus:outline-none focus:ring-0"
              href="tel:+421910464243"
            >
              <LiaPhoneSolid className="mr-2 inline-block" /> {t('+421 910 464 243')}
            </a>
          </p>
        </div>
      </div>

      <div className="relative hidden w-full md:block md:w-1/2">
        <video
          className={clsx(
            'absolute left-0 top-0 size-full object-cover transition-opacity duration-500 focus:outline-none focus:ring-0',
            { 'opacity-0': !videoLoaded, 'opacity-100': videoLoaded }
          )}
          onLoadedData={() => {
            setTimeout(() => {
              setVideoLoaded(true);
            }, 500);
          }}
          autoPlay
          muted
          loop
        >
          <source src={loginLoopLow} type="video/mp4" />
          {t('Váš prehliadač nepodporuje daný typ videa.')}
        </video>

        <div className="relative size-full">
          <img
            className={clsx('absolute inset-0 m-auto max-w-md transition-opacity duration-1000', {
              'opacity-0': !(videoLoaded && imgLoaded),
              'opacity-100': videoLoaded && imgLoaded,
            })}
            onLoad={() => {
              setImgLoaded(true);
            }}
            src={oceanLogoOutline}
            alt="Supplier Logo"
          />
        </div>
      </div>
    </div>
  );
}
