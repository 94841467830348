import { lazy } from 'react';

import t from 'core/helpers/t';
import Alert from 'core/components/Alert';
import { RouteDef } from 'app/helpers/renderRoutes';
import { getDeliveryName } from 'app/pages/Deliveries/helpers';
import { getTerminationName } from 'app/pages/Terminations/helpers';
import { EnergyDeliveryModel, EnergyDeliveryTerminationModel } from 'app/api/requests';

const General = lazy(() => import('./General'));
const Deliveries = lazy(() => import('app/pages/Deliveries'));
const DeliveryDetail = lazy(() => import('app/pages/Deliveries/DeliveryDetail'));
const Terminations = lazy(() => import('app/pages/Terminations'));
const TerminationDetail = lazy(() => import('app/pages/Terminations/TerminationDetail'));

// eslint-disable-next-line react-refresh/only-export-components
const InProgress = () => (
  <Alert
    message={t('Aktuálne nie sú evidované žiadne záznamy.')}
    hasCloseButton={false}
    className="py-2"
    hasIcon={true}
    type="info"
  />
);

const companyTabs: RouteDef[] = [
  {
    element: <General />,
    label: t('Základné údaje'),
    path: 'detail',
  },
  {
    path: 'deliveries',
    routes: [
      { element: <Deliveries />, label: t('Dodávky'), type: 'index' },
      {
        element: <DeliveryDetail />,
        label: t('Detail Dodávky'),
        name: [
          (params: { deliveryId: string; supplyPointId: string }) => {
            return `/supply-points/${params.supplyPointId}/deliveries/${params.deliveryId}`;
          },
          (res: EnergyDeliveryModel) => getDeliveryName(res),
        ],
        path: ':deliveryId',
      },
    ],
    type: 'prefix',
  },
  {
    path: 'terminations',
    routes: [
      { element: <Terminations />, label: t('Výpovede'), type: 'index' },
      {
        element: <TerminationDetail />,
        label: t('Detail výpovede'),
        name: [
          (params: { deliveryId: string; supplyPointId: string; terminationId: string }) => {
            return `/supply-points/${params.supplyPointId}/deliveries/${params.deliveryId}/terminations/${params.terminationId}`;
          },
          (res: EnergyDeliveryTerminationModel) => getTerminationName(res),
        ],
        path: ':terminationId/deliveries/:deliveryId',
      },
    ],
    type: 'prefix',
  },
  {
    element: (
      <div className="card m-6 rounded-md p-6">
        <InProgress />
      </div>
    ),
    label: t('Faktúry'),
    path: 'invoices',
  },
  {
    element: (
      <div className="card m-6 rounded-md p-6">
        <InProgress />
      </div>
    ),
    label: t('Aktivity'),
    path: 'activities',
  },
  { path: 'detail', type: 'default' },
] satisfies RouteDef[];

export function useSupplyPointTabs() {
  return companyTabs;
}
