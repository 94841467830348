import clsx from 'clsx';
import { toast } from 'react-toastify';
import { LiaUserCircle } from 'react-icons/lia';
import { useRef, useState, Fragment, useEffect, ReactNode } from 'react';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';

import t from 'core/helpers/t';
import Alert from 'core/components/Alert';
import useUser from 'core/auth/hooks/useUser';
import useLogout from 'core/auth/hooks/useLogout';
import { RouteDef } from 'app/helpers/renderRoutes';

import BreadcrumbName from './BreadcrumbName';

interface Props {
  title?: ReactNode;
  breadcrumbs?: RouteDef[];
}

export default function TopBar({ breadcrumbs, title }: Props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentUser = useUser();
  const logout = useLogout();

  // TODO: "menu" component
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="fixed z-10 flex w-[calc(100%-5rem)] items-center justify-between bg-white p-4 shadow-md">
      <div className="flex items-center">
        <div className="ml-2">
          <div className="flex items-center gap-1">
            <h1>{title}</h1>
          </div>

          <nav className="flex flex-row gap-2 text-xs text-neutral-400">
            {[{ label: t('Ocean'), path: '/' }, ...(breadcrumbs || [])].map((r, i, a) => {
              // calc all previous segments
              const pat = a
                .slice(0, i + 1)
                // @ts-ignore TODO: typing
                .map((i) => i.path.replace(/^\//, ''))
                .join('/');

              // match with current url
              const to = matchPath(pat + '/*', pathname)?.pathnameBase;

              return (
                <Fragment key={(('path' in r && r.path) || '-') + Math.random()}>
                  {i > 0 && <span> / </span>}
                  {i < a.length - 1 ? (
                    <Link className="text-neutral-400 no-underline hover:text-neutral-600" to={to || ''}>
                      {/* @ts-ignore TODO: ... */}
                      {r.name ? <BreadcrumbName route={r} /> : r.label}
                    </Link>
                  ) : (
                    /* @ts-ignore TODO: ... */
                    <span>{r.name ? <BreadcrumbName route={r} /> : r.label}</span>
                  )}
                </Fragment>
              );
            })}
          </nav>
        </div>
      </div>
      <div className="flex flex-col items-end gap-1" ref={dropdownRef}>
        <LiaUserCircle
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
          }}
          className="size-10 cursor-pointer text-primary-500"
        />
        <div>
          <small>
            {(currentUser?.name || currentUser?.surname) && (
              <>
                {currentUser.name} {currentUser.surname}
                {currentUser.email && ` (${currentUser.email})`}
              </>
            )}
          </small>
        </div>
        {dropdownOpen && (
          <div className="absolute right-0 z-50 mt-10 w-48 rounded-lg border border-gray-300 bg-white shadow-md">
            <button
              className={clsx(
                'block w-full cursor-pointer border-0 bg-transparent px-4 py-2 text-left text-gray-800 transition-colors duration-200 ease-in-out hover:bg-gray-100 disabled:cursor-not-allowed disabled:bg-neutral-200 disabled:text-neutral-500 disabled:opacity-50'
              )}
              onClick={() => {
                navigate('/profile');
                setDropdownOpen(false);
              }}
              disabled
            >
              {t('Profil')}
            </button>

            <button
              onClick={() => {
                logout().then((loggedOut) => {
                  toast(
                    <Alert
                      message={loggedOut ? t('Úspešne odhlásený.') : t('Nepodarilo sa odhlásiť.')}
                      type={loggedOut ? 'success' : 'error'}
                    />
                  );
                });
              }}
              className={clsx(
                'block w-full cursor-pointer border-0 bg-transparent px-4 py-2 text-left text-gray-800 transition-colors duration-200 ease-in-out hover:bg-gray-100 disabled:cursor-not-allowed disabled:bg-neutral-200 disabled:text-neutral-500 disabled:opacity-50'
              )}
            >
              {t('Odhlásiť sa')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
