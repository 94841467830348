import * as Sentry from '@sentry/react';
import { FC, lazy, useMemo } from 'react';
import { useCompanyRoutes } from 'company';
import { ToastContainer } from 'react-toastify';

import 'app/styles/index.css';

import { useSupplyPointRoutes } from 'supplyPoint';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import { useUserRoutes } from 'user';
import { useSupplierRoutes } from 'supplier';
import NotFoundPage from 'app/pages/NotFound';
import ApiProvider from 'core/api/ApiProvider';
import AppLayout from 'app/components/AppLayout';
import AuthProvider from 'core/auth/AuthProvider';
import AppVersion from 'app/components/AppVersion';
import WatchRouteErrors from 'app/components/WatchRouteErrors';
import renderRoutes, { RouteDef } from 'app/helpers/renderRoutes';
import useFirefoxScrollbarStyles from 'core/hooks/useFirefoxScrollbarStyles';

import Dashboard from './app/pages/Dashboard';

const Login = lazy(() => import('app/pages/Login'));
const Profile = lazy(() => import('app/pages/Profile'));
const ResetPassword = lazy(() => import('app/pages/ResetPassword'));
const ForgotPassword = lazy(() => import('app/pages/ForgotPassword'));

const App: FC = () => {
  const supplyPointRoutes = useSupplyPointRoutes();
  const supplierRoutes = useSupplierRoutes();
  const companyRoutes = useCompanyRoutes();
  const userRoutes = useUserRoutes();
  useFirefoxScrollbarStyles();

  const routes = useMemo(
    (): RouteDef[] => [...supplierRoutes, ...companyRoutes, ...userRoutes, ...supplyPointRoutes],
    [supplierRoutes, companyRoutes, userRoutes, supplyPointRoutes]
  );

  return (
    <>
      <BrowserRouter>
        <WatchRouteErrors />

        <AuthProvider>
          <ApiProvider>
            <Routes>
              {/* Unauthorized routes */}
              <Route element={<Login />} path="/login" />
              <Route element={<ForgotPassword />} path="/forgot-password" />
              <Route path="/reset-password/:token" element={<ResetPassword />} />

              {/* Authorized routes */}
              <Route element={<AppLayout routes={routes} />} path="/*">
                <Route element={<Dashboard />} index />

                {renderRoutes(routes)}

                <Route element={<Profile />} path="profile" />
              </Route>

              <Route element={<NotFoundPage />} path="*" />
            </Routes>

            <ToastContainer
              position="top-right"
              closeButton={false}
              autoClose={3000}
              hideProgressBar
              closeOnClick
              draggable
            />
          </ApiProvider>
        </AuthProvider>
      </BrowserRouter>
      <AppVersion />
    </>
  );
};

const AppComponent = import.meta.env.VITE_APP_SENTRY_DSN ? Sentry.withProfiler(App) : App;

export default AppComponent;
