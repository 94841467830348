import { lazy } from 'react';

import t from 'core/helpers/t';
import Alert from 'core/components/Alert';
import { ProductModel } from 'app/api/requests';
import { RouteDef } from 'app/helpers/renderRoutes';

const General = lazy(() => import('./General'));
const Products = lazy(() => import('./Products'));
const ProductDetail = lazy(() => import('./Products/ProductDetail'));
const DailyPriceList = lazy(() => import('./DailyPrice/DailyPriceList'));
const ProvisionRuleList = lazy(() => import('./ProvisionRules/ProvisionRuleList'));
const ProvisionRuleDetail = lazy(() => import('./ProvisionRules/ProvisionRuleDetail'));

const supplierTabs = [
  {
    element: <General />,
    label: t('Základné údaje'),
    path: `detail`,
  },
  {
    path: 'products',
    routes: [
      { element: <Products />, label: t('Produkty'), type: 'index' },
      {
        element: <ProductDetail />,
        label: t('Detail Produktu'),
        name: [
          (params: { productId: string; supplierId: string }) => {
            return `/suppliers/${params.supplierId}/products/${params.productId}`;
          },
          (res: ProductModel) => res.name,
        ],
        path: ':productId',
      },
    ],
    type: 'prefix',
  },
  {
    path: 'provision-rules',
    routes: [
      {
        element: <ProvisionRuleDetail />,
        label: t('Provízne pravidlá'), // TODO: custom name
        path: ':provisionRuleId',
      },
      { element: <ProvisionRuleList />, label: 'Provízne pravidlá', type: 'index' },
    ],
    type: 'prefix',
  },
  {
    path: 'daily-prices',
    routes: [
      {
        element: <DailyPriceList />,
        label: t('Denné ceny'),
        type: 'index',
      },
    ],
    type: 'prefix',
  },
  {
    element: (
      <div className="card m-6 rounded-md p-6">
        <Alert
          message={t('Aktuálne nie sú evidované žiadne záznamy.')}
          hasCloseButton={false}
          className="py-2"
          hasIcon={true}
          type="info"
        />
      </div>
    ),
    label: t('Prehľady'),
    path: 'overviews',
  },
  { path: 'detail', type: 'default' },
] satisfies RouteDef[];

// using hook bcs of potential re-render requirements
export default function useSupplierTabs() {
  return supplierTabs;
}
