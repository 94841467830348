import { useMergeRefs } from '@floating-ui/react';
import { Ref, HTMLProps, forwardRef, cloneElement, isValidElement } from 'react';

import useTooltipState from './useTooltipState';

export const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement>>(function TooltipTrigger(
  { children, ...props },
  propRef
) {
  const state = useTooltipState();

  const childrenRef =
    (children &&
      'ref' in (children as { ref?: Ref<HTMLElement> }) &&
      ((children as { ref?: Ref<HTMLElement> }).ref as Ref<HTMLElement>)) ||
    undefined;
  const ref = useMergeRefs([state.refs.setReference, propRef, childrenRef]);

  if (!isValidElement(children)) {
    return null;
  }

  return cloneElement(
    children,
    state.getReferenceProps({
      ref,
      ...props,
      ...(typeof children.props === 'object' ? children.props : {}),
    })
  );
});
