import { FC, ReactNode } from 'react';

import TooltipOptions from './props';
import TooltipContext from './context';
import { useTooltip } from './useTooltip';
import { TooltipTrigger } from './TooltipTrigger';
import { TooltipContent } from './TooltipContent';

const Tooltip: FC<{ content: ReactNode; children: ReactNode } & Omit<TooltipOptions, 'children'>> = ({
  children,
  content,
  ...options
}) => {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options);

  if (!content) {
    return children;
  }

  return (
    <TooltipContext.Provider value={tooltip}>
      <TooltipTrigger>{children}</TooltipTrigger>
      <TooltipContent arrowRef={tooltip.arrowRef as never} context={tooltip.context as never}>
        {content}
      </TooltipContent>
    </TooltipContext.Provider>
  );
};

export default Tooltip;
