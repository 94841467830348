import { lazy, useMemo } from 'react';

import t from 'core/helpers/t';
import { CompanyModel } from 'app/api/requests';
import { RouteDef } from 'app/helpers/renderRoutes';

import { useCompanyTabs } from './pages/CompanyDetail/tabs/useCompanyTabs';

const CompanyListPage = lazy(() => import('./pages/CompanyList'));
const CompanyDetailPage = lazy(() => import('./pages/CompanyDetail'));

export function useCompanyRoutes() {
  const companyTabs = useCompanyTabs();

  return useMemo(
    (): RouteDef[] => [
      {
        path: 'companies',
        routes: [
          {
            element: <CompanyDetailPage />,
            label: t('Spoločnosť'),
            name: [
              ({ companyId }: { companyId: string }) => `/companies/${companyId}`,
              (res: CompanyModel) => res.company_name,
            ],
            path: ':companyId',
            routes: companyTabs,
          },
          { element: <CompanyListPage />, label: t('Zoznam spoločností'), type: 'index' },
        ],
        type: 'prefix',
      },
    ],
    [companyTabs]
  );
}
