import { Link } from 'react-router-dom';
import {
  LiaCitySolid,
  LiaPlugSolid,
  LiaBurnSolid,
  LiaToolsSolid,
  LiaFileAltSolid,
  LiaIndustrySolid,
  LiaUsersCogSolid,
  LiaFileCodeSolid,
  LiaBusinessTimeSolid,
  LiaClipboardListSolid,
  LiaEnvelopeOpenTextSolid,
} from 'react-icons/lia';

import t from 'core/helpers/t';
import oceanLogo from 'app/assets/svg/ocean-logo.svg';
import DropdownLink from 'app/components/SideBar/DropdownLink.tsx';

import LinkItem from './Item';

export default function SideBar() {
  return (
    <div className="fixed left-0 top-0 flex size-full w-20 flex-col items-center bg-primary-800 py-4 text-inverse">
      <Link className="flex w-full justify-center" to="/">
        <img className="size-12" alt="Ocean Logo" src={oceanLogo} />
      </Link>
      <nav className="mt-12 flex w-full flex-col items-center">
        <LinkItem icon={<LiaIndustrySolid size={30} />} label={t('Dodávatelia')} link="/suppliers" />
        <LinkItem icon={<LiaCitySolid size={30} />} label={t('Zoznam spoločností')} link="/companies" />
        <LinkItem
          icon={
            <div className="relative flex size-6 items-center">
              <LiaPlugSolid className="absolute right-2 -rotate-45" size={27} />
              <LiaBurnSolid className="absolute left-2" size={27} />
            </div>
          }
          label={t('Zoznam odberných miest')}
          link="/supply-points"
        />
        <DropdownLink
          options={[
            {
              icon: <LiaBusinessTimeSolid size={30} />,
              label: t('Evidencia stavu výpovedí'),
              link: '/terminations',
            },
            {
              icon: <LiaFileAltSolid size={30} />,
              label: t('Denné ceny'),
              link: '/daily-prices',
            },
          ]}
          icon={<LiaClipboardListSolid size={30} />}
        />
        <DropdownLink
          options={[
            {
              icon: <LiaFileCodeSolid size={30} />,
              label: t('Šablóny dokumentov'),
              link: '/document-templates',
            },
            {
              icon: <LiaEnvelopeOpenTextSolid size={30} />,
              label: t('E-mailové šablóny'),
              link: '/email-templates',
            },
            {
              icon: <LiaUsersCogSolid size={30} />,
              label: t('Správa používateľov'),
              link: '/users',
            },
          ]}
          icon={<LiaToolsSolid size={30} />}
        />
      </nav>
    </div>
  );
}
