import { useMemo } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

import 'app/extendYup';
import TopBar from 'app/components/TopBar';
import Sidebar from 'app/components/SideBar';
import useUser from 'core/auth/hooks/useUser';
import { RouteDef } from 'app/helpers/renderRoutes';
import getBreadcrumbs from 'app/helpers/getBreadcrumbs';
import getTitleFromBreadcrumbs from 'app/helpers/getTitleFromBreadcrumbs';

export default function AppLayout({ routes }: { routes: RouteDef[] }) {
  const { pathname } = useLocation();
  const user = useUser();

  const breadcrumbs = useMemo(() => getBreadcrumbs(routes, pathname), [pathname, routes]);

  const title = useMemo(() => getTitleFromBreadcrumbs(breadcrumbs), [breadcrumbs]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <Sidebar />

      <main className="ml-20 pb-12">
        <TopBar breadcrumbs={breadcrumbs} title={title} />
        <div className="pt-24">
          <Outlet />
        </div>
      </main>
    </div>
  );
}
