import { lazy, useMemo } from 'react';

import t from 'core/helpers/t';
import { SupplierModel } from 'app/api/requests';
import { RouteDef } from 'app/helpers/renderRoutes';

import useSupplierTabs from './pages/SupplierDetail/tabs/useSupplierTabs';

const SupplierList = lazy(() => import('supplier/pages/SupplierList'));
const SupplierDetail = lazy(() => import('supplier/pages/SupplierDetail'));

export function useSupplierRoutes() {
  const supplierTabs = useSupplierTabs();

  return useMemo(
    (): RouteDef[] => [
      {
        path: 'suppliers',
        routes: [
          { element: <SupplierList />, label: t('Zoznam dodávateľov'), type: 'index' },
          {
            element: <SupplierDetail />,
            label: t('Dodávateľ'),
            name: [({ supplierId }) => `/suppliers/${supplierId}`, (res: SupplierModel) => res.name],
            path: ':supplierId',
            routes: supplierTabs,
          },
        ],
        type: 'prefix',
      },
    ],
    [supplierTabs]
  );
}
